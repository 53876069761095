import React from "react";
import Header from "../components/Header";
import "../assets/css/Naviate.css";
import data from "../assets/config/NaviateAccelerate.json"
import Template from "../components/Template";

export default function NaviateAcceleratePage() {
  return (
    <>
      <Header className="header" name="Accelerate" />
      <div className="background-container"></div>
      <Template data={data}/>
    </>
  );
}
