import Header from "../components/Header";
import Products from "../components/Products";
import "../assets/css/Home.css";

export default function Home() {
  return (
    <>
      <Header name="Home" />
      <div className="background-container"></div>
      <Products />
    </>
  );
}
