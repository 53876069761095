import React, { useEffect, useRef } from "react";
import axios from "axios";
import ReactDOMServer from "react-dom/server";


/**
 * Functional component representing an email form.
 * @param {Object} props - The props passed to the EmailForm component.
 * @param {number} users - The number of users.
 * @param {number} hours - The number of hours worked.
 * @param {number} revit - The % of time revit is used
 * @param {number} wage - The wage or hourly rate of the users.
 * @param {Array} selectedFeatures - An array of selected features or options.
 * @param {number} boost - The boost value associated with selected features.
 * @param {number} totalSavings - The total savings achieved with selected features.
 * @param {number} licenseCost - The cost of the license.
 * @param {number} returnOnInvestment - The calculated return on investment.
 * @param {function} toggleModal - A function to toggle the display of a modal.
 */
const EmailForm = (props) => {
  const {
    users,
    hours,
    revit,
    wage,
    selectedFeatures,
    boost,
    totalSavings,
    licenseCost,
    returnOnInvestment,
    toggleModal,
  } = props;
  const emailRef = useRef(null);

  useEffect(() => {
    // Load the HubSpot script once when the component mounts
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.charset = "utf-8";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  // Send the report data in an email to the provided email address
  const sendReport = async () => {
    const url = "http://localhost:5000/send-email"; // TODO: Replace with backend URL
    const htmlContent = (
      <html>
        <h2>Yearly ROI</h2>
        <p>
          <b>Users</b> : {users} <br></br>
          <b>Hours (Weekly)</b> : {hours} <br></br>
          <b>Revit Usage (%)</b> : {revit} <br></br>
          <b>Wage (Hourly)</b> : {wage} <br></br>
          <b>Selection</b> : {selectedFeatures.map((feature) => `${feature}`).join(", ")} ({Math.round(boost * 100)}%)
          <br></br>
          <p>
            {totalSavings}
            <br></br>
          </p>
          <p>
            <b>License Cost</b> : {licenseCost} <br></br>
          </p>
          <p>
            {returnOnInvestment} <br></br>
          </p>
        </p>
      </html>
    );

    // POST Request to send the report to the selected email.
    try {
      const response = await axios.post(url, {
        to: emailRef.current.value,
        subject: "Symetri ROI Report",
        html: ReactDOMServer.renderToString(htmlContent),
      });
      toggleModal();
    } catch (error) {
      console.error("Failed to send email:", error);
      // Handle the error if needed
    }
  };

  return (
    <div>
      {/* Replace the portalId and formId with your own values */}
      <form
        onSubmit={sendReport}
        id="myHubspotForm"
        action={`https://forms.hubspot.com/uploads/form/v2/2950368/ffcfb6b2-e1c9-40ee-af42-9fb7da68d20b`}
        method="POST"
      >
        <div className="hs-form-field">
          <input id="email" name="email" type="email" placeholder="Email Address" required ref={emailRef} />
          <button type="submit" form="myHubspotForm" value="Submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default EmailForm;
