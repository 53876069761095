export default function Products() {
  return (
    <div className="home-content">
      <div className="dropdown">
        <div className="dropdown-button">
          Select a Product
          <div className="dropdown-content">
            <div className="naviate">
              {" "}
              Naviate
              <div id="product-wrapper">
                <a href="./naviate-architecture">Architecture</a>
                <a href="./naviate-accelerate">Accelerate</a>
                <a href="./naviate-mep">MEP</a>
                <a href="./naviate-rebar">Rebar</a>
                <a href="./naviate-structure">Structure</a>
              </div>
            </div>
            <div className="sovelia">
              Sovelia
              <div id="product-wrapper">
                <a href="./sovelia-vault">Vault</a>
                <a href="./sovelia-inventor">Inventor</a>
              </div>
            </div>
            <div className="cqi">
              CQ
              <div id="product-wrapper">
                <a href="./cq-flexmon">Flexmon</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
