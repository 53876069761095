import React, { useState } from "react";

/**
 * Functional component representing a tooltip.
 * @param {string} text - The text content to be displayed in the tooltip.
 * @param {React.ReactNode} children - The child elements that will trigger the tooltip.
 */
const Tooltip = ({ text, children }) => {
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [activeTooltip, setActiveTooltip] = useState(null);

  // Event handler when the mouse enters the tooltip target element
  const handleMouseEnter = (event) => {
    setActiveTooltip(text);
  };

  // Event handler when the mouse moves over the tooltip target element
  const handleMouseMove = (event) => {
    const { clientX, clientY } = event;
    const tooltipX = clientX + 10;
    const tooltipY = clientY - 20;
    setActiveTooltip(text);
    setTooltipPosition({ x: tooltipX, y: tooltipY });
  };

  // Event handler when the mouse leaves the tooltip target element
  const handleMouseLeave = () => {
    setActiveTooltip(null);
  };

  const tooltipStyle = {
    top: tooltipPosition.y,
    left: tooltipPosition.x,
  };

  return (
    <div className="tooltip-container">
      <div
        className="tooltip-target"
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      {activeTooltip && (
        <div className="tooltip" style={tooltipStyle}>
          {activeTooltip}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
