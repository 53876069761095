import React, { useState, useEffect } from "react";

const Video = (props) => {
  const { product, selection } = props;
  const [videoUrl, setVideoUrl] = useState(null);
  const apiEndpoint = `http://localhost:5000/video/${product}/1000`; // Replace with your API endpoint

  useEffect(() => {
    fetch(apiEndpoint)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        console.log(response);
        return response.json();
      })
      .then((data) => {
        setVideoUrl(data.url);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  if (!videoUrl) {
    return <div>Loading...</div>;
  }

  return (
    <video controls width="640" height="360">
      <source src={videoUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default Video;
