import symLogo from "../assets/images/Naviate-Logo-White.png";
import "../stylesheets/App.css";

export default function Header(props) {
  return (
    <header>
      <a href="https://symetri.com">
        <img className="logo" src={symLogo} alt="Symetri Logo"></img>
      </a>
      <h5>WELCOME TO THE WORLD OF NAVIATE</h5>
      <h1 className="hero">ROI CALCULATOR</h1>
      <h2>{props.name}</h2>
    </header>
  );
}
