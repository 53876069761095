import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import NaviateArchitecturePage from "./pages/NaviateArchitecture";
import NaviateAcceleratePage from "./pages/NaviateAccelerate";
import NaviateMEPPage from "./pages/NaviateMEP";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/naviate-architecture" element={<NaviateArchitecturePage />}></Route>
          <Route path="/naviate-accelerate" element={<NaviateAcceleratePage />}></Route>
          <Route path="/naviate-mep" element={<NaviateMEPPage />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
