import React, { useRef, useState } from "react";
import Tooltip from "./Tooltip";
import "./../assets/css/Modal.css";
import EmailForm from "./EmailForm";
import Video from "./Video";

export default function Template(props) {
  const { data } = props;
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectionInput, setSelectionInput] = useState("11111111");
  const [inputData, setInputData] = useState({
    users: "",
    hours: "40",
    revit: "",
    wage: "",
  });
  const [boost, setBoost] = useState(0.25);
  const [licenseCost, setLicenseCost] = useState(null);
  const [returnOnInvestment, setReturnOnInvestment] = useState(data.singleLicenseCost);
  const [totalSavings, setTotalSavings] = useState("");
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };
  const cardData = data.featureList;
  const [selectedCards, setSelectedCards] = useState(cardData);
  const [deselected, setDeselected] = useState([]);

  let weight = 0;

  cardData.forEach((element) => {
    weight += element.weight * 4;
  });

  /**
   * Update the boost, styling of the card and selection input.
   * @param {integer} - The index of the card
   * @returns The updated list of selected cards
   */
  const handleCardClick = (cardIndex) => {
    setSelectedCards((prevSelectedCards) => {
      // Make a copy of the boost variable
      let updatedBoost;
      let updatedDeselected;
      const isSelected = prevSelectedCards.includes(cardIndex);
      // Make a copy of the selectionInput array
      const updatedInputArray = [...selectionInput];

      if (isSelected) {
        updatedDeselected = deselected.filter((item) => item !== cardData[cardIndex].name);
        updatedBoost = boost + cardData[cardIndex].weight / weight;
      } else {
        updatedDeselected = [...deselected, cardData[cardIndex].name];
        updatedBoost = boost - cardData[cardIndex].weight / weight;
      }

      setBoost(updatedBoost);
      setDeselected(updatedDeselected);
      updatedInputArray[cardIndex] = isSelected ? "0" : "1";
      setSelectionInput(updatedInputArray.join(""));
      return isSelected ? prevSelectedCards.filter((index) => index !== cardIndex) : [...prevSelectedCards, cardIndex];
    });
  };

  /**
   * Calculate the Return on Investment based on the input parameters.
   *
   */
  const calculateROI = () => {
    toggleModal();
    let selected = selectedCards.map((list) => list.name);
    setSelectedFeatures(selected.filter((item) => !deselected.includes(item)).filter((item) => item !== undefined));

    const parsedUsers = parseInt(inputData.users);
    const parsedHours = parseInt(inputData.hours) * 50;
    const parsedRevit = parseInt(inputData.revit) / 100;
    const parsedWage = parseInt(inputData.wage);

    let tempLicenseCost = parsedUsers > 1 ? data.multiLicenseCost : data.singleLicenseCost;
    setLicenseCost(tempLicenseCost);

    const grossROI = boost * parsedUsers * parsedHours * parsedRevit * parsedWage;
    const grossROIHours = grossROI / parsedWage;
    const netROI = grossROI - tempLicenseCost;
    const netROIHours = netROI / parsedWage;

    if (isNaN(netROI)) {
      setReturnOnInvestment("");
      setTotalSavings("");
    } else {
      setTotalSavings(`Total Savings: € ${Math.round(grossROI)} (${Math.round(grossROIHours)} hrs)`);
      setReturnOnInvestment(`Return on Investment: € ${Math.round(netROI)} (${Math.round(netROIHours)} hrs)`);
    }
  };

  return (
    <>
      <div className="content">
        <div id="input" className="input">
          <form id="inputForm">
            <label htmlFor="users">Users: </label>
            <input
              id="users"
              type="number"
              placeholder="Users"
              value={inputData.users}
              onChange={(e) =>
                setInputData((prevInputData) => ({
                  ...prevInputData,
                  users: e.target.value,
                }))
              }
              required
            />
            <br />
            <label htmlFor="workweek">Work Week: </label>
            <input
              type="number"
              placeholder="Hours"
              value={inputData.hours}
              required
              onChange={(e) =>
                setInputData((prevInputData) => ({
                  ...prevInputData,
                  hours: e.target.value,
                }))
              }
            />
            <br />
            <label htmlFor="revitusage">Revit Usage: </label>
            <input
              type="number"
              placeholder="%"
              value={inputData.revit}
              required
              onChange={(e) =>
                setInputData((prevInputData) => ({
                  ...prevInputData,
                  revit: e.target.value,
                }))
              }
            />{" "}
            <br />
            <label htmlFor="wage">Wage: </label>
            <input
              type="number"
              placeholder="Hourly"
              value={inputData.wage}
              required
              onChange={(e) =>
                setInputData((prevInputData) => ({
                  ...prevInputData,
                  wage: e.target.value,
                }))
              }
            />
          </form>{" "}
          <br />
          <br />
        </div>
        <p>Click to toggle selected features</p>
        <div className="selection">
          {cardData.map((card, index) => (
            <Tooltip key={index} text={card.description}>
              <div
                key={index}
                className={`card ${selectedCards.includes(index) ? "deselected" : ""}`}
                onClick={() => handleCardClick(index)}
              >
                {card.name}
              </div>
            </Tooltip>
          ))}
        </div>

        <div id="submit" className="submit">
          <p>
            Note: This calculator estimates projected savings based on data collected by Symetri.<br></br>
            Please note these estimations are conservative however we cannot guarantee you will achieve the projected
            savings.
          </p>
          <br></br>
          <button onClick={calculateROI}>Submit</button>
        </div>
      </div>
      {modal && (
        <div className="modal">
          <div className="overlay">
            <div className="modal-content">
              <h2>Yearly ROI</h2>
              <div>
                <b>Users</b> : {inputData.users} <br></br>
                <b>Hours (Weekly)</b> : {inputData.hours} <br></br>
                <b>Revit Usage (%)</b> : {inputData.revit} <br></br>
                <b>Wage (Hourly)</b> : {inputData.wage} <br></br>
                <b>Selection</b> : {selectedFeatures.map((feature) => `${feature}`).join(", ")} (
                {Math.round(boost * 100)}%)
                <br></br>
                <p>
                  {totalSavings}
                  <br></br>
                </p>
                <p>
                  <b>License Cost</b> : {licenseCost} <br></br>
                </p>
                <p>
                  {returnOnInvestment} <br></br>
                </p>
              </div>
              {<Video product="naviate-accelerate" selection={selectionInput} />}
              <EmailForm
                users={inputData.users}
                hours={inputData.hours}
                revit={inputData.revit}
                wage={inputData.wage}
                selectedFeatures={selectedFeatures}
                boost={boost}
                totalSavings={totalSavings}
                licenseCost={licenseCost}
                returnOnInvestment={returnOnInvestment}
                toggleModal={toggleModal}
              />
            </div>
            <button className="close-modal" onClick={toggleModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}
